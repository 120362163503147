import React, { useEffect, useState } from "react";
import { onValue, ref } from "firebase/database";
// import Toolbar from "@mui/material/Toolbar";
import {
	DataGrid, GridColDef, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams,
} from "@mui/x-data-grid";
import {
	Autocomplete,
	Box, Button, Grid, IconButton, TextField, TextFieldProps, Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { base, db } from "../firebase.js";
import AppFrame from "./shared/AppFrame";
import NewOrder from "./shared/NewOrder";
import {
	Order as OrderFirebase, Subscribtions, Cards, Orders as OrdersF,
} from "../interfaces/firebaseData";
import {
	months, years, days,
} from "../utils";
import DeleteOrder from "./shared/DeleteOrder";

interface Order extends OrderFirebase {
	id: string;
}
interface Filter {
	sellerEmail?: string;
	buyer?: string;
	year?: number;
	month?: number;
	day?: number;
}
// eslint-disable-next-line @typescript-eslint/naming-convention
function Orders() {
	const [emails, setEmails] = useState<string[]>([]);
	const [rows, setRows] = useState<Order[]>([]);
	const [subs, setSubs] = useState<Subscribtions>();
	const [cards, setCards] = useState<Cards>();
	const [open, setOpen] = useState<boolean>(false);
	const [filter, setFilter] = useState<Filter>();
	const [deleteOrderId, setDeleteOrderId] = useState<string | undefined>();
	const columns: GridColDef[] = [
		{
			field: "name",
			headerName: "Купувач",
			minWidth: 240,
			valueGetter: (params: GridValueGetterParams) => cards?.[params.row.cardId].buyerName,
		},
		{
			field: "sellerEmail",
			headerName: "Продавач",
			minWidth: 200,
			valueFormatter: (v: GridValueFormatterParams) => v?.value || "",
		},
		{
			field: "type",
			headerName: "Вид",
			minWidth: 200,
			valueGetter: (params: GridValueGetterParams) => subs?.[cards?.[params.row.cardId].subscribtionPacketId || ""]?.name,
		},
		{
			field: "price",
			headerName: "Цена",
			minWidth: 60,
			valueFormatter: (v: GridValueFormatterParams) => `${v?.value}лв` || "",
		},
		{
			field: "dateTimeSold",
			headerName: "Дата и час на продажба",
			minWidth: 180,
			valueFormatter: (v: GridValueFormatterParams) => (v?.value ? new Date(v.value).toLocaleString("bg-BG") : ""),
		},
		{
			field: "dateOfActivation",
			headerName: "Дата на активация",
			minWidth: 125,
			valueFormatter: (v: GridValueFormatterParams) => (v?.value ? new Date(v.value).toLocaleDateString("bg-BG") : ""),
		},
		{
			disableReorder: true,
			disableColumnMenu: true,
			sortable: false,
			field: "editId",
			headerName: "",
			width: 60,
			// eslint-disable-next-line react/no-unstable-nested-components
			renderCell: (params: GridRenderCellParams) => {
				return (
					<IconButton
						color="error"
						onClick={() => {
							setDeleteOrderId(params.row.id);
						}}
					>
						<Delete />
					</IconButton>
				);
			},
		},
	];

	useEffect(() => {
		const queryOrders = ref(db, `${base}/Orders`);
		const querySubs = ref(db, `${base}/SubscribtionPackets`);
		const queryCards = ref(db, `${base}/Cards`);
		onValue(ref(db, `${base}/Emails`), (snapshot) => {
			const data: string = snapshot.val();
			console.log("data", data);
			if (snapshot.exists()) {
				setEmails(data?.split(",")?.map((e) => e.trim()));
			} else {
				setEmails([]);
			}
		});
		onValue(querySubs, (snapshot) => {
			const data: Subscribtions = snapshot.val();
			if (snapshot.exists()) {
				setSubs(data);
			} else {
				setSubs(undefined);
			}
		});
		onValue(queryCards, (snapshot) => {
			const data: Cards = snapshot.val();
			if (snapshot.exists()) {
				setCards(data);
			} else {
				setCards(undefined);
			}
		});
		onValue(queryOrders, (snapshot) => {
			const data: OrdersF = snapshot.val();
			if (snapshot.exists()) {
				const newRows: Order[] = [];
				Object.entries(data).forEach((entry) => {
					const [key, value] = entry;
					newRows.push({
						id: key,
						...value,
					});
				});
				setRows(newRows.reverse());
			} else {
				setRows([]);
			}
		});
	}, [deleteOrderId]);
	const filteredRows = rows.filter((row) => {
		const date = new Date(row.dateTimeSold);
		return row?.sellerEmail?.toLowerCase()?.includes(filter?.sellerEmail || "")
			&& cards?.[row.cardId]?.buyerName?.toLowerCase().includes(filter?.buyer || "")
			&& (filter?.year ? date.getFullYear() === filter.year : true)
			&& (filter?.month || 0 === filter?.month ? date.getMonth() === filter.month : true)
			&& (filter?.day ? date.getDate() === filter.day : true);
	});
	const price = filteredRows.reduce((a, b) => a + b.price, 0);

	return (
		<AppFrame>
			<NewOrder open={open} onClose={() => { setOpen(false); }} />
			<DeleteOrder orderId={deleteOrderId} onClose={() => { setDeleteOrderId(undefined); }} />
			<Grid container mb={3} alignItems="flex-start">
				<Box flex={1}>
					<Typography variant="h6">
						Поръчки -
						{" "}
						{price}
						лв
					</Typography>
				</Box>
				<Button variant="outlined" onClick={() => setOpen(true)}>Създай нова</Button>
			</Grid>
			<Grid container columnSpacing={2}>
				<Grid item xs={6} md={3}>
					<Autocomplete
						disablePortal
						id="sellers"
						options={[
							{
								id: undefined,
								label: "Всички",
							},
							...emails.map((v) => {
								return {
									id: v,
									label: v,
								};
							}),
						]}
						sx={{ width: "100%", mb: 2 }}
						renderInput={(params: TextFieldProps) => <TextField {...params} label="Продавач" />}
						onChange={(e, value) => {
							setFilter({ ...filter, sellerEmail: value?.id?.toLowerCase() });
						}}
					/>
				</Grid>
				<Grid item xs={6} md={3}>
					<TextField
						label="Купувач"
						fullWidth
						placeholder="Име на купувач..."
						onChange={(newValue) => {
							setFilter({ ...filter, buyer: newValue.target.value });
						}}
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={4} md={2}>
					<Autocomplete
						disablePortal
						id="years"
						options={[
							{
								id: undefined,
								label: "Всички",
							},
							...years.map((v) => {
								return {
									id: v,
									label: v,
								};
							}),
						]}
						sx={{ width: "100%", mb: 2 }}
						renderInput={(params: TextFieldProps) => <TextField {...params} label="Година" />}
						onChange={(e, value) => {
							setFilter({ ...filter, year: value?.id });
						}}
					/>
				</Grid>
				<Grid item xs={4} md={2}>
					<Autocomplete
						disablePortal
						id="months"
						options={[
							{
								id: undefined,
								label: "Всички",
							},
							...months.map((v, i) => {
								return {
									id: i,
									label: v,
								};
							}),
						]}
						sx={{ width: "100%", mb: 2 }}
						renderInput={(params: TextFieldProps) => <TextField {...params} label="Месец" />}
						onChange={(e, value) => {
							setFilter({ ...filter, month: value?.id });
						}}
					/>
				</Grid>
				<Grid item xs={4} md={2}>
					<Autocomplete
						disablePortal
						id="days"
						options={[
							{
								id: undefined,
								label: "Всички",
							},
							...days.map((v) => {
								return {
									id: v,
									label: v,
								};
							}),
						]}
						sx={{ width: "100%", mb: 2 }}
						renderInput={(params: TextFieldProps) => <TextField {...params} label="Ден" />}
						onChange={(e, value) => {
							setFilter({ ...filter, day: value?.id });
						}}
					/>
				</Grid>
			</Grid>
			<div style={{ width: "100%", marginBottom: "40px", height: "calc(100svh - 240px)" }}>
				<DataGrid
					disableColumnSelector
					rowSelection={false}
					rowHeight={80}
					rows={filteredRows}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: { page: 0, pageSize: 10 },
						},
					}}
					pageSizeOptions={[10, 20]}
				/>
			</div>
		</AppFrame>
	);
}

export default Orders;

import React, { useEffect, useState } from "react";
import { onValue, ref } from "firebase/database";
// import Toolbar from "@mui/material/Toolbar";
import {
	DataGrid, GridColDef, GridRenderCellParams, GridValueFormatterParams,
} from "@mui/x-data-grid";
import {
	Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography,
} from "@mui/material";
import {
	Close, Visibility, Edit, Delete,
} from "@mui/icons-material";
import QRCode from "qrcode";
import { base, db } from "../firebase.js";
import AppFrame from "./shared/AppFrame";
import SearchBar from "./shared/SearchBar";
import NewCard from "./shared/NewCard";
import EditCard from "./shared/EditCard";
import { Card as CardFirebase, Subscribtion } from "../interfaces/firebaseData";
import QR from "./shared/QR";
import DeleteCard from "./shared/DeleteCard";

interface Card extends CardFirebase {
	id: string;
}
interface CardsData {
	[id: string]: CardFirebase
}
interface SubsData {
	[id: string]: Subscribtion
}


// eslint-disable-next-line @typescript-eslint/naming-convention
function Cards() {
	const [rows, setRows] = useState<Card[]>([]);
	const [subs, setSubs] = useState<SubsData>();
	const [open, setOpen] = useState<boolean>(false);
	const [selectedCard, setSelectedCard] = useState<Card>();
	const [qr, setQr] = useState<string>();
	const [searchValue, setSearchValue] = useState("");
	const [editCard, setEditCard] = useState<string>();
	const [deleteCard, setDeleteCard] = useState<string | undefined>();

	const columns: GridColDef[] = [
		{
			field: "buyerPicture",
			headerName: "Снимка",
			minWidth: 130,
			disableReorder: true,
			disableColumnMenu: true,
			sortable: false,
			renderCell: (params: GridRenderCellParams) => {
				if (params.row.buyerPicture) {
					return <img height="80px" width="auto" src={params.row.buyerPicture} />;
				}
			},
		},
		{ field: "buyerName", headerName: "Име", minWidth: 220 },
		{ field: "phone", headerName: "Телефон", minWidth: 220 },
		{
			field: "subscribtionPacketId",
			headerName: "Вид",
			minWidth: 200,
			valueFormatter: (v: GridValueFormatterParams) => subs?.[v.value]?.name || "",
		},
		{
			disableReorder: true,
			disableColumnMenu: true,
			sortable: false,
			field: "id",
			headerName: "QR Код",
			width: 80,
			// eslint-disable-next-line react/no-unstable-nested-components
			renderCell: (params: GridRenderCellParams) => {
				return (
					<IconButton
						color="primary"
						onClick={async () => {
							const src = await QRCode.toDataURL(
								params.row.id,
								{
									type: "image/png",
									width: 1000,
								},

							);
							setQr(src);
							setSelectedCard(params.row);
						}}
					>
						<Visibility />
					</IconButton>
				);
			},
		},
		{
			disableReorder: true,
			disableColumnMenu: true,
			sortable: false,
			field: "editId",
			headerName: "",
			width: 50,
			// eslint-disable-next-line react/no-unstable-nested-components
			renderCell: (params: GridRenderCellParams) => {
				return (
					<IconButton
						color="primary"
						onClick={() => {
							setEditCard(params.row.id);
						}}
					>
						<Edit />
					</IconButton>
				);
			},
		},
		{
			disableReorder: true,
			disableColumnMenu: true,
			sortable: false,
			field: "deleteId",
			headerName: "",
			width: 50,
			// eslint-disable-next-line react/no-unstable-nested-components
			renderCell: (params: GridRenderCellParams) => {
				return (
					<IconButton
						color="error"
						onClick={() => {
							setDeleteCard(params.row.id);
						}}
					>
						<Delete />
					</IconButton>
				);
			},
		},
	];

	useEffect(() => {
		const queryCards = ref(db, `${base}/Cards`);
		const querySubs = ref(db, `${base}/SubscribtionPackets`);
		onValue(querySubs, (snapshot) => {
			const data: SubsData = snapshot.val();
			if (snapshot.exists()) {
				setSubs(data);
			} else {
				setSubs(undefined);
			}
		});
		return onValue(queryCards, (snapshot) => {
			const data: CardsData = snapshot.val();
			if (snapshot.exists()) {
				const newRows: Card[] = [];
				Object.entries(data).forEach((entry) => {
					const [key, value] = entry;
					newRows.push({
						id: key,
						...value,
					});
				});
				setRows(newRows.reverse());
			} else {
				setRows([]);
			}
		});
	}, []);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
	};

	const filteredRows = rows.filter((row) => {
		return row.buyerName.toLowerCase().includes(searchValue.toLowerCase());
	});

	return (
		<>
			<Dialog open={!!selectedCard}>
				<DialogTitle sx={{ m: 0, p: 2 }}>
					{selectedCard?.buyerName}
					<IconButton
						aria-label="close"
						onClick={() => {
							setQr(undefined);
							setSelectedCard(undefined);
						}}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>
					<QR qr={qr || ""} name={selectedCard?.buyerName} />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {
						setQr(undefined);
						setSelectedCard(undefined);
					}}
					>
						Затвори
					</Button>
				</DialogActions>
			</Dialog>
			<AppFrame>
				<NewCard open={open} onClose={() => { setOpen(false); }} />
				<DeleteCard cardId={deleteCard} onClose={() => { setDeleteCard(undefined); }} />
				<EditCard editCardId={editCard} onClose={() => { setEditCard(undefined); }} />
				<Grid container mb={3} alignItems="flex-start">
					<Box flex={1}>
						<Typography variant="h6">Карти</Typography>
					</Box>
					<Button variant="outlined" onClick={() => setOpen(true)}>Създай нова</Button>
				</Grid>
				<SearchBar value={searchValue} onChange={handleSearchChange} />
				<div style={{ width: "100%", marginBottom: "40px", height: "calc(100svh - 240px)" }}>
					<DataGrid
						disableColumnSelector
						rowSelection={false}
						rowHeight={80}
						rows={filteredRows}
						columns={columns}
						initialState={{
							pagination: {
								paginationModel: { page: 0, pageSize: 10 },
							},
						}}
						pageSizeOptions={[10, 20]}
					/>
				</div>
			</AppFrame>
		</>
	);
}

export default Cards;

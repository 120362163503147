import { ref, onValue, push } from "firebase/database";
import React, {
	useCallback, useEffect, useRef, useState,
} from "react";
import {
	Box,
	Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Select, SelectChangeEvent, Tab, Tabs, TextField, Typography,
} from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { WithStyles, createStyles, withStyles } from "@mui/styles";
import {
	CameraAlt, FileUpload, Close as CloseIcon,
} from "@mui/icons-material";
import Webcam from "react-webcam";
// eslint-disable-next-line import/no-extraneous-dependencies
import QRCode from "qrcode";
import { base, db } from "../../firebase.js";
import { Subscribtions } from "../../interfaces/firebaseData";
import QR from "./QR";

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

interface Card {
	subscribtionPacketId?: string;
	buyerName?: string;
	buyerPicture?: string | null;
	phone?: string;
}

const styles = () => createStyles({
	tabRoot: {
		flex: 1,
		maxWidth: "none",
	},
	imgWrapper: {
		width: "400px",
		maxWidth: "100%",
		margin: "20px auto 0 auto",
	},
	imgHolder: {
		overflow: "hidden",
		background: "gray",
		marginBottom: "20px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	qr: {
		display: "block",
		width: "300px",
		maxWidth: "100%",
	},
});

interface Props extends WithStyles<typeof styles> {
	open: boolean;
	onClose: () => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function NewCard(props: Props) {
	const { classes } = props;
	const webcamRef = useRef<any>(null);
	const cardRef = useRef<any>(null);
	const [subs, setSubs] = useState<Subscribtions>({});
	const [card, setCard] = useState<Card | undefined>();
	const [qr, setQr] = useState<string | undefined>();
	const [clicked, setClicked] = useState<boolean>(false);
	const [tabValue, setTabValue] = React.useState(0);
	useEffect(() => {
		onValue(ref(db, `${base}/SubscribtionPackets`), (snapshot) => {
			if (snapshot.exists()) {
				setSubs(snapshot.val());
			}
		});
	}, []);
	const handleChange = (event: SelectChangeEvent) => {
		setCard({ ...card, subscribtionPacketId: event.target.value });
	};
	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
		setCard({ ...card, buyerPicture: undefined });
	};
	const imgUpload = () => {
		const file = (window?.document?.querySelector("input[type=file]") as any)?.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				setCard({ ...card, buyerPicture: reader.result?.toString() });
			};
		}
	};
	cardRef.current = card;
	const capture = useCallback(() => {
		if (webcamRef?.current) {
			const imageSrc = (webcamRef.current as any).getScreenshot();
			setCard({ ...cardRef.current, buyerPicture: imageSrc });
		}
	}, [webcamRef]);
	const add = async () => {
		setClicked(true);
		const uid = await push(ref(db, `${base}/Cards`), card);
		const src = await QRCode.toDataURL(
			uid?.key || "",
			{
				type: "image/png",
				width: 1000,
			},

		);
		setQr(src);
		setClicked(false);
	};
	const close = () => {
		setQr(undefined);
		setCard(undefined);
		props.onClose();
	};

	return (
		<Dialog open={props.open}>
			<DialogTitle sx={{ m: 0, p: 2 }}>
				Добави карта
				<IconButton
					aria-label="close"
					onClick={close}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				{!qr ? (
					<>
						<Typography mb={1}>Вид карта*</Typography>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							label="Карта"
							onChange={handleChange}
							fullWidth
							sx={{ mb: 3 }}
							required
						>
							{Object.entries(subs).map(([key, value]) => <MenuItem value={key}>{value.name}</MenuItem>)}
						</Select>
						<Typography mb={1}>Име на собственик*</Typography>
						<TextField
							sx={{ mb: 3 }}
							fullWidth
							label="Име"
							onChange={(e) => setCard({ ...card, buyerName: e.target.value })}
							required
						/>
						<Typography mb={1}>Телефон</Typography>
						<TextField
							sx={{ mb: 3 }}
							fullWidth
							label="Телефон"
							onChange={(e) => setCard({ ...card, phone: e.target.value })}
							type="number"
						/>
						<Typography mb={1}>Снимка</Typography>
						<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
							<Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
								<Tab
									className={classes.tabRoot}
									label={(
										<Grid container alignItems="center" justifyContent="center">
											<Box mr={1}><FileUpload /></Box>
											от файл
										</Grid>
									)}
									{...a11yProps(0)}
								/>
								<Tab
									className={classes.tabRoot}
									label={(
										<Grid container alignItems="center" justifyContent="center">
											<Box mr={1}><CameraAlt /></Box>
											Камера
										</Grid>
									)}
									{...a11yProps(1)}
								/>
							</Tabs>
						</Box>
						<Box className={classes.imgWrapper}>
							<Button
								variant="outlined"
								component={0 === tabValue ? "label" : "div"}
								fullWidth
								sx={{ mb: 2 }}
								// eslint-disable-next-line no-nested-ternary
								onClick={() => {
									if (1 === tabValue) {
										if (card?.buyerPicture) {
											setCard({ ...card, buyerPicture: undefined });
										} else {
											capture();
										}
									}
								}}
							>
								{0 === tabValue && (
									<>
										качи Снимка
										<Box sx={{ display: "none" }}>
											<input
												id="files"
												style={{ visibility: "hidden" }}
												type="file"
												accept="image/*"
												onChange={imgUpload}
											/>
										</Box>
									</>
								)}
								{1 === tabValue && !card?.buyerPicture && "Снимай"}
								{1 === tabValue && card?.buyerPicture && "Нова снимка"}
							</Button>
							<Box className={classes.imgHolder}>
								{card?.buyerPicture && <img width="100%" height="auto" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} src={card?.buyerPicture as string} alt="" />}
								{!card?.buyerPicture && 1 === tabValue && (
									<Webcam
										audio={false}
										height="auto"
										width="100%"
										ref={webcamRef}
										screenshotFormat="image/jpeg"
										videoConstraints={{ facingMode: "environment" }}
									/>
								)}
							</Box>
						</Box>
					</>
				) : <QR qr={qr} name={card?.buyerName} />}
			</DialogContent>
			<DialogActions>
				{qr ? (
					<Button
						onClick={close}
					>
						Затвори
					</Button>
				) : (
					<Button
						disabled={!card?.buyerName || !card.subscribtionPacketId || clicked}
						onClick={add}
					>
						Добави
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}
export default withStyles(styles)(NewCard);
